.autorecharge-main {
  box-sizing: border-box;
  border: 1px solid #757575;
  border-radius: 3px;
  padding: 16px;
}
.autorecharge-main .info {
  margin-left: 2rem;
  margin-top: 15px;
}
.autorecharge-main.checkbox-halo {
  box-sizing: border-box;
  border: 2px solid #DB2A00;
  border-radius: 3px;
}
.autorecharge-specialoffer {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 4, 10, 0.14), 0px 3px 1px -2px rgba(0, 4, 10, 0.04), 0px 1px 5px 0px rgba(0, 4, 10, 0.2);
  border-radius: 3px;
  margin-top: 15px !important;
  padding-top: 20px;
  padding-left: 16px;
  border-top: 4px solid #FF4300;
}
.specialoffer-text {
  box-sizing: border-box;
  width: 100px;
  height: 25px;
  background: #ff4300;
  border-radius: 4px;
  color: white;
  text-align: center;
}
.specialoffer-title {
  box-sizing: border-box;
  width: 95%;
  height: 40%;
  color: #282828;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 28px;
}
.specialoffer-description {
  box-sizing: border-box;
  width: 95%;
  height: 70px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 21px;
  margin-top: 3px;
}
.specialoffer-checkbox-details {
  width: 95%;
  height: 65px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 22px;
  margin-top: 15px;
}
.auto-title {
  margin-top: auto !important;
}
@media screen and (min-width: 64rem) {
  .payment-auto-recharge [class*=able-MessageSection] {
    max-width: 100%;
  }
}
.payment-auto-recharge [class*=able-MessageSection] button,
.payment-auto-recharge [class*=able-MessageSection] button:hover,
.payment-auto-recharge [class*=able-MessageSection] button:focus,
.payment-auto-recharge [class*=able-MessageSection] button:active {
  color: #ff4300;
}
.payment-auto-recharge [class*=able-MessageSection] button svg,
.payment-auto-recharge [class*=able-MessageSection] button:hover svg,
.payment-auto-recharge [class*=able-MessageSection] button:focus svg,
.payment-auto-recharge [class*=able-MessageSection] button:active svg,
.payment-auto-recharge [class*=able-MessageSection] button svg path,
.payment-auto-recharge [class*=able-MessageSection] button:hover svg path,
.payment-auto-recharge [class*=able-MessageSection] button:focus svg path,
.payment-auto-recharge [class*=able-MessageSection] button:active svg path,
.payment-auto-recharge [class*=able-MessageSection] button svg use,
.payment-auto-recharge [class*=able-MessageSection] button:hover svg use,
.payment-auto-recharge [class*=able-MessageSection] button:focus svg use,
.payment-auto-recharge [class*=able-MessageSection] button:active svg use {
  fill: #ff4300 !important;
}
.payment-auto-recharge .able-text-style a {
  color: #ff4300;
}
.payment-auto-recharge [class*=able-Checkbox] {
  width: 99%;
  margin-left: 1px;
  margin-right: 1px;
}
